<template>
  <div class="reportes">
    <div class="flex flex-col md:flex-row">
      <div class="main-content flex-1 bg-gray-100 mt-12 md:mt-12 pb-24 md:pb-5">
        <div class="bg-gray-800 pt-3">
          <div
            class="
              rounded-tl-3xl
              bg-gradient-to-r
              from-blue-900
              to-gray-800
              p-4
              shadow
              text-2xl text-white
            "
          >
            <h3 class="font-bold pl-2 font-sans">Reportes</h3>
          </div>
        </div>
        <div class="p-3">
          <v-select
            v-model="report"
            :options="reports"
            label="descripcion"
            placeholder="Seleccione un reporte"
            class="
              w-80
              inline-block
              mr-3
              bg-gray-200
              text-gray-600 text-xs
              rounded
            "
          >
          </v-select>
        </div>
        <div class="p-3 h-screen">
          <iframe
            v-if="report != undefined && report != null && report != ''"
            class="w-full h-screen"
            :src="
              'https://juanabapl.juanabonita.com.co:446/GestionCasosReports/Reports/' +
              //'https://localhost:44384/Reports/' +
              report.nombre +
              '.aspx?reportname=' +
              report.nombre2 +
              '?idUsuario=' +
              idUsuarioAux
            "
            frameborder="0"
          ></iframe>
          <iframe
            v-else
            class="w-full h-screen"
            :src="'https://juanabapl.juanabonita.com.co:446/GestionCasosReports/Reports/null.aspx?reportname=null'"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/logic/auth";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  name: "Reportes",
  components: {},
  data() {
    return {
      report: [],
      reports: [
        /*{
          nombre: "RptInformeLideresContabilidad2021",
          descripcion: "Plan líderes 2021",
        },*/
        {
          nombre: "RptInformeCasosDetallados",
          nombre2: "RptInformeCasosDetallados",
          descripcion: "Casos Detallados Por Usuario",
        },
        {
          nombre: "RptInformeCasosDetallados",
          nombre2: "RptInformeCasosDetalladosGrafica",
          descripcion: "Casos Detallados Por Usuario Grafica",
        },
      ],
      idUsuarioAux: "",
    };
  },
  methods: {
    ...mapMutations([
      "loadNavbar", 
      "hiddenNavbar", 
      "logueo", 
      "openNavCasos", 
      "openModalCargando",
      "closeModalCargando"
    ]),
  },
  created() {
    this.openNavCasos()
    try {
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      if (user.token == "" || user.token == undefined) {
        this.$router.push("/");
      } else {
        this.display = "block";
        this.usuario = user.usuario;
        this.idUsuarioAux = user.id;
        this.loadNavbar(user);
      }
      if(this.permisosUsuario.tipoUsuario == "EXTERNO"){
        if (this.auxayuda == false){
          this.abrirAyuda()
        }
        let data = {
          nombre: "RptInformeCasosDetallados",
          nombre2: "InfDetalleCambiosYDevolucionesGZ",
          descripcion: "Informe Detalle Cambios y Devoluciones",
        };
        this.reports.push(data);
      }
    } catch (err) {
      this.$alert(
        "Por favor vuelve a ingresar a la plataforma",
        "Expiro la Sesion!",
        "error"
      );
      this.$router.push("/");
    }
    //RptInformeCasosDetalladosJefes
    if (user.id == 392) {
      let data = {
        nombre: "RptInformeCasosDetallados",
        nombre2: "InfDetalleCambiosYDevoluciones",
        descripcion: "Informe Detalle Cambios y Devoluciones (CDR)",
      };
      this.reports.push(data);
    }
    if (user.id == 517) {
      let data = {
        nombre: "RptInformeCasosDetallados",
        nombre2: "InfDetalleCambiosYDevoluciones",
        descripcion: "Informe Detalle Cambios y Devoluciones (CDR)",
      };
      this.reports.push(data);
    }
    if (user.id == 515) {
      let data = {
        nombre: "RptInformeCasosDetallados",
        nombre2: "InfDetalleCambiosYDevoluciones",
        descripcion: "Informe Detalle Cambios y Devoluciones (CDR)",
      };
      this.reports.push(data);
    }
    if (user.id == 502) {
      let data1 = {
        nombre: "RptInformeCasosDetallados",
        nombre2: "RptInformeCasosDetalladosGeneralGC",
        descripcion: "Casos Detallados EN TODA LA PLATAFORMA",
      };
      let data2 = {
        nombre: "RptInformeCasosDetallados",
        nombre2: "InfDetalleCambiosYDevoluciones",
        descripcion: "Informe Detalle Cambios y Devoluciones (CDR)",
      };
      let data3 = {
        nombre: "RptInformeCasosDetallados",
        nombre2: "InfDetalleCambiosYDevolucionesGZ",
        descripcion: "Informe Detalle Cambios y Devoluciones",
      };
      this.reports.push(data1);
      this.reports.push(data2);
      this.reports.push(data3);
    }
  },
  computed: {
    ...mapState(["esJefe","permisosUsuario"])
  }
};
</script>
